import React, { useEffect, useState } from "react"
import { Button, Col, Collapse, Divider, Modal, Row, Spin, Steps } from "antd"
import { CloseCircleFilled } from "@ant-design/icons"
import { Formik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import axios from "axios"
import moment from "moment"

import SelectRooms from "../../components/BookingDetails/SelectRooms"
import BookingSummary from "../../components/BookingDetails/BookingSummary"
import BookingHeader from "../../components/BookingHeader/BookingHeader"
import FindaHotel from "../../components/FindaHotel/FindaHotel"
import AddOns from "../../components/BookingDetails/AddOns"
import ReviewAndPay from "../../components/BookingDetails/ReviewAndPay/ReviewAndPay"
import CustomModal from "../CustomModal"

import TaxesAndServiceDetails from "./Modals/TaxesAndServiceDetails"
import PromoForm from "./PromoForm"
import { GET_PROMO_CODES } from "./graphql"

import { dateFormatBooking } from "../FindaHotel/initailValue"

import { PLATFORM } from "../../enum"
import { RoomsAndRatesI, AddOnsI } from "../../interface"
import { script, initialValuesReviewPay, validationSchema } from "./constants"
import { BOOKING_ROOMSANDRATES, BOOKING_SUBMIT } from "./graphql"
import { dateFormatApi } from "../FindaHotel/initailValue"

import "./BookingDetails.scss"
import { currencyFormatter } from "../../utilities/format-currency"

const { Panel } = Collapse

import {
  KeyCircleIcon,
  SquarePlusCircleIcon,
  WalletCircleIcon,
  ChevronRighIcon,
  EditPenIcon,
  InformationIcon,
} from "../../utilities/icons/icons"
import PaymentNotApplicable from "./Modals/PaymentNotApplicable"

const { Step } = Steps

const BookingContent = ({
  brandName,
  dataGraphql,
  datePickerHotel,
  details,
  hotelID,
  rooms,
  setActiveTab,
  setDetails,
  setShowHotelDetails,
}) => {
  const initialEmptyRateSelected = rooms.map(item => null)
  const paymentOptions = details?.payment_options[0]
  const [addOnsAmount, setAddOnsAmount] = useState<number>(0)
  const [activeRoom, setActiveRoom] = useState<number>(1)
  const [activePanel, setActivePanel] = useState<string[]>(["1"])
  const [addOnsTax, setAddOnsTax] = useState<number>(0)
  const [addOnsList, setAddOnsList] = useState<AddOnsI[]>([])
  const [countryCodeList, setCountryCodeList] = React.useState<any[]>([])
  const [currentStep, setCurrentStep] = useState(0)
  const [hiddenForm, setHiddenForm] = useState<any>(<></>)
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false)
  const [isSubmitModalVisible, setIsSubmitModalVisible] =
    useState<boolean>(false)
  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false)
  const [isBookingSummaryModalVisible, setIsBookingSummaryModalVisible] =
    useState(false)
  const [subtotalAmount, setSubtotalAmount] = useState<number>(0)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [partyArray, setPartyArray] = useState<any[]>([])
  const [promoSubmit, setPromoSubmit] = useState<Object | null>(null)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [isPromoValidated, setIsPromoValidated] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const [rateSelectedOnRooms, setRateSelectedOnRooms] = useState<any[]>(
    initialEmptyRateSelected
  )
  const [reload] = useState<string>(script)
  const [roomsAndRates, setRoomsAndRates] = useState<RoomsAndRatesI>()
  const [roomsAndRatesWithTax, setRoomsAndRatesWithTax] = useState<number>(0)
  const [roomsAndRatesAmount, setRoomsAndRatesAmount] = useState<number>(0)
  const [roomsAndRatesTaxOnly, setRoomsAndRatesTaxOnly] = useState<number>(0)
  const [discountAmount, setDiscountAmount] = useState<number>(0)
  const [discountedRoomsTax, setDiscountedRoomsTax] = useState<[any] | null>(
    null
  )
  const [taxAmount, setTaxAmount] = useState<number>(0)
  const [termsAndConditions, setTermsAndConditions] = useState<string>("")
  const [title, setTitle] = useState("")
  const [totalAmount, setTotalAmount] = useState(0)
  const [showTaxesAndService, setShowTaxesAndService] = useState<boolean>(false)
  const [bookingSubmit] = useMutation(BOOKING_SUBMIT)

  const [selectedPromo, setSelectedPromo] = useState<any>()
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("")
  const [showPaymentNotApplicableModal, setShowPaymentNotApplicableModal] =
    useState<boolean>(false)
  const [paymentsNotApplicable, setPaymentsNotApplicable] = useState<string[]>(
    []
  )

  const date1 = new Date(datePickerHotel[0])
  const date2 = new Date(datePickerHotel[1])
  const diffTime = date2.getTime() - date1.getTime()
  const dayCount = diffTime / (1000 * 3600 * 24)

  const showBookingSummaryModal = () => {
    setIsBookingSummaryModalVisible(true)
  }

  const hideBookingSummaryModal = () => {
    setIsBookingSummaryModalVisible(false)
  }

  const handleEditRoom = key => {
    if (currentStep === 1) {
      setCurrentStep(currentStep - 1)
    } else if (currentStep === 2) {
      setCurrentStep(currentStep - 2)
    }
    setActiveRoom(key)
  }

  const roomDataInquiry = {
    checkInDate: moment(datePickerHotel?.[0]).format(dateFormatApi),
    checkOutDate: moment(datePickerHotel?.[1]).format(dateFormatApi),
    hotelId: hotelID,
  }

  const partyParsed = rooms.map(item => {
    const children: any[] = []
    while (children.length > rooms.length) {
      children.push(12)
    }

    return {
      adults: item.adult,
      children,
    }
  })

  const handlePanelChange = e => {
    setActivePanel(e)
  }

  const removeAddOnItem = key => {
    addOnsList.map((item, index) => {
      if (index === key) {
        return (item.selected = false)
      }
    })
    setAddOnsList([...addOnsList])
  }

  const partnerDataInput = rateSelectedOnRooms
    .filter(item => item !== undefined)
    .filter(item => item !== null)
    .map((item, index) => {
      return {
        room_id: partyArray[index].room_id.toString(),
        partner_data_id: item?.partnerDataID,
      }
    })

  const partyInput = partyArray.map(party => {
    return {
      adults: party.adults,
      children: party.children,
    }
  })

  const promoCodeInput = {
    ...roomDataInquiry,
    final_price_at_booking: { amount: 0 },
    final_price_at_checkout: { amount: roomsAndRatesWithTax },
    party: partyInput,
    partner_data: partnerDataInput,
  }

  // old calculation for reference
  // useEffect(() => {
  //   setSubtotalAmount(addOnsAmount + roomsAndRatesAmount)
  // }, [addOnsAmount, roomsAndRatesAmount])

  useEffect(() => {
    setSubtotalAmount(roomsAndRatesAmount - discountAmount)
  }, [discountAmount, roomsAndRatesAmount])

  useEffect(() => {
    setTotalAmount(subtotalAmount + taxAmount + addOnsAmount)
  }, [discountAmount, subtotalAmount, taxAmount])

  useEffect(() => {
    const vat = subtotalAmount * 0.12
    const serviceCharge = subtotalAmount * 0.05
    const taxTotal = vat + serviceCharge
    setTaxAmount(taxTotal + addOnsTax)
  }, [roomsAndRatesTaxOnly, addOnsTax, subtotalAmount])

  useEffect(() => {
    setRoomsAndRatesAmount(
      rateSelectedOnRooms.reduce((total, value) => {
        const ratePrice = value ? value.amount : 0
        return total + ratePrice
      }, 0)
    )
    setRoomsAndRatesWithTax(
      rateSelectedOnRooms.reduce((total, value) => {
        const ratePrice = value ? value.amountWithTax : 0
        return total + ratePrice
      }, 0)
    )
    !isPromoValidated &&
      setRoomsAndRatesTaxOnly(
        rateSelectedOnRooms.reduce((total, value) => {
          const taxPrice = value ? value.tax : 0
          return total + taxPrice
        }, 0)
      )
    setAddOnsAmount(
      addOnsList.reduce((total, value) => {
        const addOnPrice = value.selected ? value.net_rates * value.counter : 0
        return total + addOnPrice
      }, 0)
    )
    setAddOnsTax(
      addOnsList.reduce((total, value) => {
        const addOnTax = value.selected ? value.tax * value.counter : 0
        return total + addOnTax
      }, 0)
    )
  }, [rateSelectedOnRooms, addOnsList])

  useEffect(() => {
    if (dataGraphql) {
      setCountryCodeList(dataGraphql.countryCodes)

      if (typeof window !== "undefined") {
        const tag = document.createElement("script")
        tag.append(reload)

        const firstScriptTag = document.getElementsByTagName("script")[0]
        firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag)
      }
    }
  }, [])

  const { loading, data } = useQuery(BOOKING_ROOMSANDRATES, {
    variables: {
      input: {
        ...roomDataInquiry,
        party: partyParsed,
      },
    },
  })

  const promoCodesQuery = useQuery(GET_PROMO_CODES, {
    variables: {
      input: promoCodeInput,
    },
  })

  useEffect(() => {
    if (typeof data !== "undefined") {
      const addOnsData = [...data.getAddOns.addOns].map(item => ({
        ...item,
        counter: 0,
        selected: false,
      }))
      setAddOnsList(addOnsData)
      setPartyArray(data.getAvailableRooms.party_information)
      setRoomsAndRates(data.getAvailableRooms)
      setTermsAndConditions(data.getAvailableRooms.terms_and_conditions)
    }
  }, [data])

  useEffect(() => {
    rateSelectedOnRooms.indexOf(null) >= 0
      ? setIsDisabled(true)
      : setIsDisabled(false)
  }, [rateSelectedOnRooms])

  const handleProceed = () => {
    if (currentStep === 0 && rateSelectedOnRooms.indexOf(null) >= 0) {
      setActiveRoom(rateSelectedOnRooms.indexOf(null) + 1)
    } else {
      setCurrentStep(currentStep + 1)
    }

    // } else if (currentStep < 2 && addOnsList.length) {
    //   setCurrentStep(currentStep + 1)
    // }
  }

  const handleBack = () => {
    if (currentStep >= 2) {
      setCurrentStep(currentStep - 1)
    }
  }
  const onFinish = async values => {
    setLoadingSubmit(true)
    setIsSubmitModalVisible(true)
    if (typeof window !== "undefined") {
      window.onbeforeunload = null
    }
    const finalAmount = totalAmount.toFixed(2)
    let checkoutData

    const newValues = {
      UserName: values.first_name + " " + values.last_name,
      UserEmail: values.email_address,
      UserContact: values.mobile_no,
      ProdDesc: `Hotel Stay at ${roomsAndRates?.name}`,
      special_instruction: values.special_instructions,
      Amount: `${finalAmount}`,
      hotel_id: hotelID,
      platform: PLATFORM.WEBSITE,
      use_saved_card: false,
      wallet_id: "",
      checkInDate: roomDataInquiry.checkInDate,
      checkOutData: roomDataInquiry.checkOutDate,
      partner_data_id: rateSelectedOnRooms
        .filter(item => item !== undefined)
        .filter(item => item !== null)
        .map((item, index) => {
          return {
            room_id: partyArray[index].room_id.toString(),
            partner_data_id: item?.partnerDataID,
          }
        }),
    }

    const customerListInitial = values.companions.map(item => {
      return {
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        phone_number: item.phone_number,
      }
    })

    const inputData = {
      hotelId: newValues.hotel_id,
      partner_data: newValues.partner_data_id,
      checkInDate: newValues.checkInDate,
      checkOutDate: newValues.checkOutData,
      party: partyParsed,
      platform: PLATFORM.WEBSITE,
      first_name: values.first_name,
      last_name: values.last_name,
      country_code: "+63",
      mobile_no: newValues.UserContact,
      email_address: newValues.UserEmail,
      special_instruction: newValues.special_instruction,
      customer_list: customerListInitial,
      promo_details: promoSubmit || {},
      add_ons: addOnsList
        .filter(item => item.selected)
        .map(item => {
          return {
            id: item.id,
            count: item.counter,
          }
        }),
      final_price_at_booking: {
        amount:
          values.payment === "Pay Online via iPay88" ? roomsAndRatesWithTax : 0,
      },
      final_price_at_checkout: {
        amount: values.payment === "Pay at Hotel" ? roomsAndRatesWithTax : 0,
      },
    }

    if (values.payment === "Pay Online via iPay88") {
      const config = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("act")}` || null,
        },
      }
      checkoutData = await axios
        .post(
          `${process.env.GATSBY_REST_API_URL}/payment/checkout`,
          newValues,
          config
        )
        .then(res => res.data)
        .catch(() => {
          setLoadingSubmit(false)
          setIsErrorModalVisible(true)
        })

      inputData["reference_no"] = checkoutData.RefNo
    }

    const { data } = await bookingSubmit({
      variables: {
        Input: inputData,
      },
    })

    if (typeof data !== "undefined" && data !== null) {
      const result = await data.bookingSubmit
      setLoadingSubmit(false)
      if (!result) {
        setIsErrorModalVisible(true)
      } else {
        setIsSuccessModalVisible(true)
        if (result.status === "BOOKED") {
          const redirect = `${process.env.GATSBY_SITE_URL}booking-confirmation/?status=booked&reservation_id=${data.bookingSubmit.reservation_id}&amount=${newValues.Amount}&name=${newValues.UserName}`

          setTimeout(() => window.location.replace(redirect), 1500)
        } else if (result.status === "PENDING") {
          const paymentForm = (
            <form
              action="https://payment.ipay88.com.ph/epayment/entry.asp"
              method="post"
              name="ePayment"
              id="form"
            >
              <input
                type="hidden"
                name="MerchantCode"
                value={checkoutData.MerchantCode}
              />
              <input
                type="hidden"
                name="PaymentId"
                value={checkoutData.PaymentId}
              />
              <input type="hidden" name="RefNo" value={checkoutData.RefNo} />
              <input type="hidden" name="Amount" value={newValues.Amount} />
              <input type="hidden" name="Currency" value="PHP" />
              <input type="hidden" name="ProdDesc" value={newValues.ProdDesc} />
              <input type="hidden" name="UserName" value={newValues.UserName} />
              <input
                type="hidden"
                name="UserEmail"
                value={newValues.UserEmail}
              />
              <input
                type="hidden"
                name="UserContact"
                value={newValues.UserContact}
              />
              <input
                type="hidden"
                name="SignatureType"
                value={checkoutData.SignatureType}
              />
              <input
                type="hidden"
                name="Signature"
                value={checkoutData.Signature}
              />
              <input
                type="hidden"
                name="ResponseURL"
                value={`${process.env.GATSBY_REST_API_URL}/payment/response`}
              />
              <input
                type="hidden"
                name="BackendURL"
                value={`${process.env.GATSBY_REST_API_URL}/payment/backend`}
              />
              <input
                type="submit"
                value="Submit"
                id="submitButton"
                style={{ display: "none" }}
              />
            </form>
          )

          setHiddenForm(paymentForm)
        }
      }
    }
  }

  useEffect(() => {
    setTimeout(() => document.getElementById("submitButton")?.click(), 1500)
  }, [hiddenForm])

  useEffect(() => {
    if (currentStep === 0) {
      setActivePanel(["1"])
    } else if (currentStep === 1) {
      setActivePanel(["2"])
    } else {
      setActivePanel([])
    }
  }, [currentStep])

  const addOnsSummary = addOnsList.map((item, index) => {
    return item.selected ? (
      <div className="booking-summary-details-cont" key={index}>
        <div className="booking-summary-variable">
          {`${item.name} (x${item.counter})`}
        </div>
        <div className="booking-summary-value">
          {`Php ${item.net_rates * item.counter}.00`}{" "}
          <CloseCircleFilled onClick={() => removeAddOnItem(index)} />
        </div>
      </div>
    ) : null
  })

  const noAddOnsSummary = (
    <span className="booking-summary-none">No Selected Add-Ons</span>
  )
  const checkAddOns =
    addOnsSummary.filter(item => item !== null).length === 0 ? false : true

  useEffect(() => {
    const isApplicableOnPayHotel =
      selectedPromo?.deal_info.applicable_on_pay_at_hotel
    const hasSelectedPromo = selectedPromo !== undefined
    const hasSelectedPayment = selectedPaymentOption.length > 0

    const removePayment = payment => {
      if (paymentsNotApplicable.indexOf(payment) === -1) {
        setPaymentsNotApplicable([...paymentsNotApplicable, payment])
      }
      setShowPaymentNotApplicableModal(true)
    }

    if (!hasSelectedPayment || !hasSelectedPayment) {
      return setShowPaymentNotApplicableModal(false)
    }

    if (!hasSelectedPromo) return

    switch (selectedPaymentOption) {
      case "Pay at Hotel":
        if (!isApplicableOnPayHotel) {
          removePayment(selectedPaymentOption)
        }
        break
      default:
        removePayment(selectedPaymentOption)
        break
    }
  }, [selectedPaymentOption])

  useEffect(() => {
    setSelectedPaymentOption("")
    setPaymentsNotApplicable([])
  }, [selectedPromo])

  return (
    <div className="booking-details-content-wrapper">
      <BookingHeader
        address={roomsAndRates?.address}
        brandName={brandName}
        datePickerHotel={datePickerHotel}
        details={details}
        hotelID={hotelID}
        name={roomsAndRates?.name}
        setActiveTab={setActiveTab}
        setDetails={setDetails}
        setShowHotelDetails={setShowHotelDetails}
      />

      <div className="checkout-details-wrapper paddingpX50">
        <FindaHotel
          data={dataGraphql}
          dropdownClassName="certain-category-search-dropdown"
          placeholder="Search Hotel and Location"
          containerId="findhotelBookingPage"
          formID="formHotel1"
          bookingFormStatus={false}
        />
      </div>
      <div className="stepper-wrapper paddingpX50">
        <Row className="stepper" align="middle">
          <Col span={18}>
            <Steps className="site-navigation-steps" current={currentStep}>
              <Step title="Room & Rates" icon={<KeyCircleIcon />} />
              {addOnsList.length > 0 && (
                <Step title="Add-Ons" icon={<SquarePlusCircleIcon />} />
              )}
              <Step title="Review and Pay" icon={<WalletCircleIcon />} />
            </Steps>
          </Col>
          <Col span={6}>
            {currentStep > 0 && (
              <Button className="edit-btn edit-btn-hide" onClick={handleBack}>
                Back
              </Button>
            )}

            {addOnsList.length > 0 && currentStep !== 2 && (
              <Button
                style={{ marginLeft: "1em" }}
                className="primary-btn"
                type="primary"
                onClick={handleProceed}
                disabled={isDisabled}
              >
                Proceed
              </Button>
            )}

            {!addOnsList.length && currentStep !== 1 && (
              <Button
                style={{ marginLeft: "1em" }}
                className="primary-btn"
                type="primary"
                onClick={handleProceed}
                disabled={isDisabled}
              >
                Proceed
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <div className="main-content-wrapper paddingpX50">
        <Row gutter={20}>
          <Col xs={22} md={23} xl={16} style={{ marginLeft: "1.3rem" }}>
            {currentStep === 0 && (
              <SelectRooms
                activeRoom={activeRoom}
                loading={loading}
                partyArray={partyArray}
                paymentOptions={paymentOptions}
                rateSelectedOnRooms={rateSelectedOnRooms}
                roomsAndRatesDetails={roomsAndRates?.rooms}
                roomDataInquiry={roomDataInquiry}
                setActiveRoom={setActiveRoom}
                setRateSelectedOnRooms={setRateSelectedOnRooms}
              />
            )}
            {currentStep === 1 && addOnsList.length > 0 && (
              <AddOns addOnsList={addOnsList} setAddOnsList={setAddOnsList} />
            )}
            {currentStep === 2 && (
              <Spin spinning={loadingSubmit} size="large">
                <Formik
                  initialValues={initialValuesReviewPay}
                  validationSchema={validationSchema}
                  validateOnChange={false}
                  validateOnBlur={false}
                  enableReinitialize={true}
                  onSubmit={onFinish}
                  render={formikBag => (
                    <ReviewAndPay
                      {...{
                        countryCodeList,
                        formikBag,
                        paymentOptions,
                        setMessage,
                        setTitle,
                        setSelectedPaymentOption,
                        termsAndConditions,
                        selectedPaymentOption,
                        paymentsNotApplicable,
                        rooms,
                      }}
                    />
                  )}
                />
                <div>{hiddenForm}</div>
                <CustomModal
                  visible={isSubmitModalVisible}
                  noCancelBtn={true}
                  noOKBtn={true}
                  isSuccessIcon={true}
                  message="You are now being redirected. Please refrain from navigating away from this page to avoid duplicate transactions."
                />
                <CustomModal
                  titleMessage={title}
                  visible={isSuccessModalVisible}
                  noCancelBtn={true}
                  noOKBtn={true}
                  isSuccessIcon={true}
                  message={message}
                />
                <CustomModal
                  titleMessage="Oops! Booking Unsuccessful"
                  visible={isErrorModalVisible}
                  noCancelBtn={true}
                  onOk={() => setIsErrorModalVisible(false)}
                  isErrorIcon={true}
                  message="There's seem to be an error during your booking transaction please check your connection or call our customer service (CS number)"
                />
              </Spin>
            )}
          </Col>

          <Col
            xs={30}
            xl={7}
            className="mobile-booking-total-amount mobile-view-only"
          >
            <Row justify="space-between">
              <Col style={{ marginLeft: "2rem", marginTop: "1rem" }}>
                <h4> Total Amount:</h4>
                <h1>
                  {currencyFormatter(totalAmount, {
                    significantDigits: 2,
                    symbol: "Php",
                  })}
                </h1>
              </Col>
              <Col
                xs={11}
                md={7}
                style={{ display: "flex" }}
                onClick={showBookingSummaryModal}
              >
                <h3>View summary</h3>
                <ChevronRighIcon style={{ paddingTop: "2rem" }} />
              </Col>
            </Row>
          </Col>

          <Modal
            title="Booking Summary"
            visible={isBookingSummaryModalVisible}
            onCancel={hideBookingSummaryModal}
            footer={null}
          >
            <Col>
              <Row justify="space-between">
                <Col className="booking-summary-label">Check-In</Col>
                <Col className="bnooking-summary-data">
                  {moment(datePickerHotel?.[0]).format(dateFormatBooking)}
                </Col>
              </Row>

              <Row justify="space-between">
                <Col className="booking-summary-label">Check-out</Col>
                <Col className="bnooking-summary-data">
                  {moment(datePickerHotel?.[1]).format(dateFormatBooking)}
                </Col>
              </Row>

              <Row justify="space-between">
                <Col span={24} className="booking-summary-rooms-and-rates">
                  <Collapse
                    expandIconPosition="right"
                    activeKey={activePanel}
                    onChange={handlePanelChange}
                  >
                    <Panel
                      header="ROOMS & RATES"
                      extra={currencyFormatter(roomsAndRatesAmount, {
                        significantDigits: 2,
                        symbol: "Php",
                      })}
                      key="1"
                    >
                      {rooms.map((room, key) => (
                        <div key={key}>
                          <div>
                            <div className="booking-summary-details-cont">
                              <div className="booking-summary-variable">
                                {`${room.label} ${key + 1} `}
                                <Button
                                  type="text"
                                  className="edit-txt-btn"
                                  style={{
                                    color: rateSelectedOnRooms[key]
                                      ? "#d82229"
                                      : "#bdcadb",
                                  }}
                                  onClick={() => handleEditRoom(key + 1)}
                                >
                                  <EditPenIcon
                                    onClick={hideBookingSummaryModal}
                                  />
                                  Edit
                                </Button>
                              </div>
                              <div className="booking-summary-value">
                                {rateSelectedOnRooms[key] ? (
                                  rateSelectedOnRooms[key].roomName
                                ) : (
                                  <span className="booking-summary-none">
                                    No Selected Room Yet
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="booking-summary-details-cont">
                              <div className="booking-summary-variable">
                                Rate Selected
                              </div>
                              <div className="booking-summary-value">
                                {rateSelectedOnRooms[key] ? (
                                  rateSelectedOnRooms[key].rateName
                                ) : (
                                  <span className="booking-summary-none">
                                    No Selected Rate
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="booking-summary-details-cont">
                              <div className="booking-summary-variable">
                                Guest/s
                              </div>
                              <div className="booking-summary-value">
                                {room.adult > 1
                                  ? `${room.adult} Adults`
                                  : `${room.adult} Adult`}
                                ,{" "}
                                {room.children > 1
                                  ? `${room.children} Children`
                                  : `${room.children} Child`}
                              </div>
                            </div>
                            <div className="booking-summary-details-cont">
                              <div className="booking-summary-variable">
                                Amount
                              </div>
                              <div className="booking-summary-value">
                                {rateSelectedOnRooms[key]
                                  ? currencyFormatter(
                                      rateSelectedOnRooms[key].amount,
                                      {
                                        significantDigits: 2,
                                        symbol: "Php",
                                      }
                                    )
                                  : `Php 0.00`}
                              </div>
                            </div>
                          </div>
                          {rooms.length !== key + 1 && <Divider />}
                        </div>
                      ))}
                    </Panel>

                    <Panel
                      header="ADD-ONS"
                      extra={currencyFormatter(addOnsAmount, {
                        significantDigits: 2,
                        symbol: "Php",
                      })}
                      key="2"
                    >
                      {checkAddOns ? addOnsSummary : noAddOnsSummary}
                    </Panel>
                    <Panel header="PROMO CODE" key="3">
                      <PromoForm
                        isPromoValidated={isPromoValidated}
                        dayCount={dayCount}
                        promoCodeInput={promoCodeInput}
                        promoCodesQuery={promoCodesQuery}
                        rateSelectedOnRooms={rateSelectedOnRooms}
                        setDiscountAmount={setDiscountAmount}
                        setDiscountedRoomsTax={setDiscountedRoomsTax}
                        setIsPromoValidated={setIsPromoValidated}
                        setPromoSubmit={setPromoSubmit}
                        setRoomsAndRatesTaxOnly={setRoomsAndRatesTaxOnly}
                        setSelectedPromo={setSelectedPromo}
                      />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
              <Row justify="space-between" className="gray-background">
                <Col className="booking-summary-label ">Subtotal</Col>
                <Col span={7} className="bnooking-summary-data">
                  {currencyFormatter(subtotalAmount, {
                    significantDigits: 2,
                    symbol: "Php",
                  })}
                </Col>
              </Row>
              <Row justify="space-between" className="gray-background">
                <Col className="booking-summary-label ">Discount</Col>
                <Col span={8} className="bnooking-summary-data">
                  -
                  {currencyFormatter(discountAmount, {
                    significantDigits: 2,
                    symbol: "Php",
                  })}
                </Col>
              </Row>
              <Row justify="space-between" className="gray-background">
                <Col className="booking-summary-label ">
                  Tax and Service Charge
                  <InformationIcon
                    onClick={() => setShowTaxesAndService(true)}
                  />
                </Col>
                <Col span={8} className="bnooking-summary-data">
                  {currencyFormatter(taxAmount, {
                    significantDigits: 2,
                    symbol: "Php",
                  })}
                </Col>
              </Row>
              <Row
                justify="center"
                style={{ paddingTop: ".5rem" }}
                className=" booking-summary-label modal-total-amount"
              >
                <Col>Total Amount:</Col>
                <Col>
                  {currencyFormatter(totalAmount, {
                    significantDigits: 2,
                    symbol: "Php",
                  })}
                </Col>
              </Row>
            </Col>
          </Modal>

          <Col
            style={{ marginLeft: "auto", marginRight: "1rem" }}
            className="mobile-view-only"
          >
            {currentStep > 0 && (
              <Button
                className="edit-btn"
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                Back
              </Button>
            )}
            {currentStep !== 2 && (
              <Button
                style={{ marginLeft: "1em" }}
                className="primary-btn "
                type="primary"
                onClick={handleProceed}
                disabled={isDisabled}
              >
                Proceed
              </Button>
            )}
          </Col>

          <TaxesAndServiceDetails
            addOnsList={addOnsList}
            discountedRoomsTax={discountedRoomsTax}
            onCancel={() => setShowTaxesAndService(false)}
            rateSelectedOnRooms={rateSelectedOnRooms}
            visible={showTaxesAndService}
          />

          <Col style={{ marginLeft: "1.3rem" }} xl={7} md={23} xs={22}>
            <BookingSummary
              activePanel={activePanel}
              addOnsList={addOnsList}
              dayCount={dayCount}
              currentStep={currentStep}
              datePickerHotel={datePickerHotel}
              partyArray={partyArray}
              rateSelectedOnRooms={rateSelectedOnRooms}
              roomDataInquiry={roomDataInquiry}
              rooms={rooms}
              roomsAndRatesWithTax={roomsAndRatesWithTax}
              setActivePanel={setActivePanel}
              setActiveRoom={setActiveRoom}
              setCurrentStep={setCurrentStep}
              setAddOnsList={setAddOnsList}
              setPromoSubmit={setPromoSubmit}
              setRoomsAndRatesWithTax={setRoomsAndRatesWithTax}
              setTotalAmount={setTotalAmount}
              totalAmount={totalAmount}
              setSelectedPromo={setSelectedPromo}
              discountAmount={discountAmount}
              setDiscountAmount={setDiscountAmount}
              subtotalAmount={subtotalAmount}
              setSubtotalAmount={setSubtotalAmount}
            />
          </Col>
        </Row>
        <PaymentNotApplicable
          visible={showPaymentNotApplicableModal}
          onCancel={() => {
            setShowPaymentNotApplicableModal(false)
            setSelectedPaymentOption("")
          }}
        />
      </div>
    </div>
  )
}

export default BookingContent
